import React from 'react';
import { getPlaces } from '../../vendor/places';
import { groupPlaces, SEARCH_ATTRIBUTES } from './lib';
import { PlacesGroupView } from '../places-group/places-group-view';

const searchPlaces = ({ places, query }) => {
	const results = [];
	const lowerCaseQuery = query && query.toLowerCase();

	if (!lowerCaseQuery) return groupPlaces(places);

	for (const searchAttribute of SEARCH_ATTRIBUTES) {
		const searchResults = [...places].filter((place) => {
			if (results.includes(place)) return;
			return place[searchAttribute].toString().toLowerCase().includes(lowerCaseQuery);
		});

		if (searchResults) results.push(...searchResults)
	}

	if (!results) return groupPlaces(places);

	return groupPlaces(results);
};

export const PlacesListView = ({ searchQuery }) => {
	const places = getPlaces();
	const searchedPlaces = searchPlaces({ places: places, query: searchQuery });
	localStorage.clear()

	return (
		<article className="places">
			{searchedPlaces.map((item, index) => (<PlacesGroupView placeGroup={item} key={index} />))}
		</article>
	);
};
