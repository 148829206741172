import React, { Fragment } from "react";
import { ReturnButon } from "../return-button/return-button";
import { ShareButton } from "../share-button/share-button";

export const ControlButtons = () => (
	<div className="control-buttons">
		<ReturnButon />
		<ShareButton />
	</div>
)
