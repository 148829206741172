import { createSlice } from "@reduxjs/toolkit"
import { setBodyIsScrolled, setModalOpen } from "../action"

const initialState = {
  isBodyScrolled: false,
  isModalOpen: false
}

const userData = createSlice({
  name: "UserData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setBodyIsScrolled, (state, action) => {
        state.isBodyScrolled = action.payload
      })
      .addCase(setModalOpen, (state, action) => {
        state.isModalOpen = action.payload
      })
  }
})

const { reducer } = userData

export default reducer
