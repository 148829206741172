import React, { Fragment, forwardRef } from 'react';
import { Filters } from './filters-list/filters-list';

export const Search = ({ setSearchQuery }) => {
	const onSearchChange = (evt) => setSearchQuery(evt.target.value)

	return (
		<Fragment>
			<article className={'search background-wrapper'}>
				<div className="search__info">
					<h1 className="search__title">Где поесть?</h1>
					<p className="search__text">
						Заботливо собрали данные для вас и актуализируем их каждый месяц
					</p>
				</div>
			</article>
			<div className='search__filters search__filters--sticky'>
				<span className="search__input--wrapper">
					<input
						type="text"
						name="search__input"
						className="search__input"
						placeholder="Введи, что ищешь"
						onChange={onSearchChange}
					/>
				</span>
				<Filters />
			</div>
		</Fragment>
	);
};
