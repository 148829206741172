import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './src/app/app';
import { Provider } from 'react-redux';
import { store } from './src/store';
import './public/styles.css';
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
);
