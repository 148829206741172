import React from "react";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../store/action";
import { toast } from "react-toastify";

export const ShareButton = () => {
	const dispatch = useDispatch();


	const copyLink = () => {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			// true for mobile device
			dispatch(setModalOpen(true))
		} else {
			// false for not mobile device
			navigator.clipboard.writeText(document.location.href)
			toast.success("Copied")
		}
	}

	return (
		<button className="share-button" onClick={copyLink}>
			<img src="/images/share-button.png" className="share-button__icon" />
		</button>
	)
}
