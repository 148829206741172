import React, { useCallback, useEffect, useRef } from 'react';
import { sortingOrder } from '../../const';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../store/action';
import { getFilter } from '../../store/app-data/app-data-selectors';
import { useSelector } from 'react-redux';

const Filter = ({ item, onFilterClick }) => {
	const dispatch = useDispatch();
	const ref = useRef()
	const filter = useSelector(getFilter)

	const filterClickHandler = () => {
		onFilterClick(item);
		dispatch(setFilter(item));
	};

	useEffect(() => {
		if (filter === ref.current.textContent) ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
	}, [filter])

	return (
		<li className={`filters__item ${filter === item ? 'filters__item--scrolled' : ''}`} onClick={filterClickHandler} ref={ref}>
			<a className="filters__item__text">{item}</a>
		</li>
	);
};

export const Filters = () => {
	const filter = useSelector(getFilter)
	let placesTitlesArray;
	const ref = useRef();

	const onFilterClick = useCallback((filter) => {
		const titleToScroll = placesTitlesArray.find((item) => item.textContent === filter);

		if (titleToScroll) {
			titleToScroll.scrollIntoView();
			window.scrollBy({ top: titleToScroll.getBoundingClientRect().y - 127, behavior: 'smooth' });
		}
	}, []);

	useEffect(() => {
		placesTitlesArray = Array.from(document.querySelectorAll('.places-title'));
	}, [filter]);


	return (
		<ul className="filters" ref={ref}>
			{sortingOrder.map((item, index) => (
				<Filter
					item={item}
					onFilterClick={onFilterClick}
					key={index}
				/>
			))}
		</ul>
	);
};
