import React, { useCallback, useEffect, useState } from 'react';
import { Search } from '../../components/search-view';
import { PlacesListView } from '../../components/places-list/places-list-view';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../store/action';
import { throttle } from '../../const';

export const Main = () => {
	const dispatch = useDispatch();
	const [searchQuery, setSearchQuery] = useState(null);
	const [windowIsScrolled, setWindowIsScrolled] = useState(false);

	const setFilterOnScroll = useCallback((placesTitles) => {
		const scrolledHeader = placesTitles.filter((item) => item.getBoundingClientRect().y <= 130).pop();

		if (window.scrollY <= 247) {
			setWindowIsScrolled(false)
			dispatch(setFilter(null));
			return;
		}

		setWindowIsScrolled(true);

		if (scrolledHeader === null) return

		if (!windowIsScrolled) dispatch(setFilter(scrolledHeader.textContent))
	}, []);


	useEffect(() => {
		const placesTitle = Object.values(document.querySelectorAll('.places-title'));
		const filters = Object.values(document.querySelectorAll('.filters__item'))

		const onBodyScroll = () => {
			setFilterOnScroll(placesTitle, filters)
		}

		const handleScroll = throttle(onBodyScroll, 10);

		document.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, []);

	return (
		<>
			<Search setSearchQuery={setSearchQuery} />
			<PlacesListView searchQuery={searchQuery} />
		</>
	);
};
