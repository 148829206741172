const DELAY = 500;

export const sortingOrder = [
  'Ресторан',
  'Кафе',
  'Кофейня',
  'Пекарня',
  'Готовая еда',
  'Бар',
  'Столовая',
  'Фастфуд',
];

export const sliceNames = {
  AppData: 'AppData',
  UserData: 'UserData',
};

export const debounce = (callback, delay = DELAY) => {
  let timeoutId;
  return (...rest) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, rest), delay);
  };
};

export function throttle(callback, timeout) {
  let timer = null

  return function perform(...args) {
    if (timer) return

    timer = setTimeout(() => {
      callback(...args)

      clearTimeout(timer)
      timer = null
    }, timeout)
  }
}
