import { createSlice } from "@reduxjs/toolkit";
import { setFilter, setSelectedFilter, setSelectedPlace } from "../action";

const initialState = {
  filter: null,
  selectedFilter: null,
  selectedPlace: null
}

const appData = createSlice({
  name: 'AppData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setFilter, (state, action) => {
        state.filter = action.payload
      })
      .addCase(setSelectedFilter, (state, action) => {
        state.selectedFilter = action.payload
      })
      .addCase(setSelectedPlace, (state, action) => {
        state.selectedPlace = action.payload;
      })
  }
})

const { reducer } = appData

export default reducer
