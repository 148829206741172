import React from "react";
import { Bounce, ToastContainer } from "react-toastify";

export const CustomToastContainer = () =>
	<ToastContainer
		position="top-center"
		autoClose={500}
		hideProgressBar
		newestOnTop={false}
		closeOnClick={false}
		rtl={false}
		pauseOnFocusLoss={false}
		draggable={false}
		pauseOnHover={false}
		theme="light"
		transition={Bounce}
	/>
